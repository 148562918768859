<template>
  <div>
    <div
      v-if="isPhoneNumberRequired"
      v-bind:class="amplifyUI.inputLabel"
    >{{$Amplify.I18n.get('Mobile Phone')}} *</div>
    <div
      v-if="!isPhoneNumberRequired"
      v-bind:class="amplifyUI.inputLabel"
    >{{$Amplify.I18n.get('Mobile Phone')}}</div>
    <div v-bind:class="amplifyUI.selectInput">
      <input
        v-model="local_phone_number"
        v-bind:class="[amplifyUI.input, isInvalid ? 'invalid': '']"
        :placeholder="$Amplify.I18n.get(getPlaceholder)"
        autofocus
        v-on:keyup="emitPhoneNumberChanged"
        v-bind:data-test="auth.genericAttrs.phoneNumberInput"
        v-mask="'+1 (###) ###-####'"
      />
    </div>
  </div>
</template>
<script>
import * as AmplifyUI from "@aws-amplify/ui";
import countries from "@/assets/countries";
import { auth } from "@/assets/data-test-attributes";

export default {
  name: "PhoneField",
  props: ["required", "invalid", "placeholder", "defaultCountryCode"],
  data() {
    return {
      countryCode: this.defaultCountryCode || "1",
      local_phone_number: "",
      countries,
      //   amplifyUI: AmplifyUI,
      amplifyUI: {
        // phone: {},
        // formSection: "Form__formSection___3tqxz",
        // sectionHeader: "Section__sectionHeader___13iO4",
        // sectionBody: "Section__sectionBody___3DCrX",
        // formField: "Form__formField___2DWhT",
        inputLabel: "Input__inputLabel___nQolz",
        input: "Input__input___2Sh1s",
        selectInput: "SelectInput__selectInput___3efO4"
        // sectionFooter: "Section__sectionFooter___1QlDn",
        // sectionFooterPrimaryContent:
        //   "Section__sectionFooterPrimaryContent___36Gb1",
        // button: "Button__button___1FrBC",
        // sectionFooterSecondaryContent:
        //   "Section__sectionFooterSecondaryContent___3cjOa",
        // a: "Anchor__a___3JUCG"
      },
      auth
    };
  },
  computed: {
    isPhoneNumberRequired() {
      return this.required;
    },
    isInvalid() {
      return !!this.invalid;
    },
    getPlaceholder() {
      return this.placeholder || "Enter your phone number";
    }
  },
  methods: {
    emitPhoneNumberChanged() {
      this.$emit("phone-number-changed", {
        countryCode: this.countryCode,
        local_phone_number: this.local_phone_number
      });
    }
  }
};
</script>
