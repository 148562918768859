<template>
  <div v-bind:class="amplifyUI.formSection" v-bind:data-test="auth.signUp.section">
    <div
      v-bind:class="amplifyUI.sectionHeader"
      v-bind:data-test="auth.signUp.headerSection"
    >{{this.options.header}}</div>
    <div v-bind:class="amplifyUI.sectionBody" v-bind:data-test="auth.signUp.bodySection">
      <div
        v-bind:class="amplifyUI.formField"
        v-for="signUpField in this.orderedSignUpFields"
        :signUpField="signUpField.key"
        v-bind:key="signUpField.key"
      >
        <div v-if="signUpField.key === 'distributor'">
          <div v-bind:class="amplifyUI.inputLabel">Code</div>
          <input
            :type="signUpField.type"
            v-bind:class="[amplifyUI.input, signUpField.invalid ? 'invalid': '']"
            v-model="signUpField.value"
            placeholder="Enter code"
            v-on:change="clear(signUpField)"
          />
        </div>
        <div v-else-if="signUpField.key === 'store'">
          <div v-bind:class="amplifyUI.inputLabel">Store Code</div>
          <input
            :type="signUpField.type"
            v-bind:class="[amplifyUI.input, signUpField.invalid ? 'invalid': '']"
            v-model="signUpField.value"
            placeholder="Enter code if applicable"
            v-on:change="clear(signUpField)"
            v-bind:data-test="auth.signUp.nonPhoneNumberInput"
          />
        </div>
        <div v-else-if="signUpField.key !== 'phone_number'">
          <div
            v-bind:class="amplifyUI.inputLabel"
          >{{$Amplify.I18n.get(signUpField.label)}} {{signUpField.required ? '*': ''}}</div>
          <input
            :type="signUpField.type"
            v-bind:class="[amplifyUI.input, signUpField.invalid ? 'invalid': '']"
            v-model="signUpField.value"
            :placeholder="$Amplify.I18n.get(signUpField.label)"
            v-on:change="clear(signUpField)"
            v-bind:data-test="auth.signUp.nonPhoneNumberInput"
          />
        </div>
        <div v-if="signUpField.key === 'phone_number'">
          <phone-field
            v-bind:required="signUpField.required"
            placeholder="+1"
            v-on:phone-number-changed="phoneNumberChanged"
          />
        </div>
      </div>
    </div>
    <div v-bind:class="amplifyUI.sectionFooter" v-bind:data-test="auth.signUp.footerSection">
      <span v-bind:class="amplifyUI.sectionFooterPrimaryContent">
        <v-btn
          v-bind:class="amplifyUI.button"
          v-on:click="signUp"
          v-bind:data-test="auth.signUp.createAccountButton"
          :loading="buttonLoad"
          dark
          color="#002b44"
        >{{$Amplify.I18n.get('Create Account')}}</v-btn>
      </span>
      <span v-bind:class="amplifyUI.sectionFooterSecondaryContent">
        {{$Amplify.I18n.get('Have an account? ')}}
        <a
          v-bind:class="amplifyUI.a"
          v-on:click="signIn"
          v-bind:data-test="auth.signUp.signInLink"
        >{{$Amplify.I18n.get('Sign in')}}</a>
      </span>
    </div>
    <div class="error mt-3" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
import Vue from "vue";
import Vue2Filters from "vue2-filters";
import orderBy from "lodash.orderby";
import { AmplifyEventBus } from "aws-amplify-vue";
import * as AmplifyUI from "@aws-amplify/ui";
import countries from "aws-amplify-vue/src/assets/countries";
import signUpWithUsername, {
  signUpWithEmailFields,
  signUpWithPhoneNumberFields,
} from "aws-amplify-vue/src/assets/default-sign-up-fields";
import {
  labelMap,
  composePhoneNumber,
} from "aws-amplify-vue/src/components/authenticator/common";
// import PhoneField from "aws-amplify-vue/src/components/authenticator/PhoneField";
import PhoneField from "@/components/PhoneField";
import { auth } from "aws-amplify-vue/src/assets/data-test-attributes";

Vue.use(Vue2Filters);
// Vue.component("amplify-phone-field", PhoneField);

export default {
  name: "SignUp",
  props: ["signUpConfig", "usernameAttributes"],
  components: {
    PhoneField,
  },
  data() {
    let defaultSignUpFields = signUpWithUsername;
    if (this.usernameAttributes === "email") {
      // defaultSignUpFields = signUpWithEmailFields;
      defaultSignUpFields = this.signUpConfig.signUpFields;
    } else if (this.usernameAttributes === "phone_number") {
      defaultSignUpFields = signUpWithPhoneNumberFields;
    }

    return {
      auth,
      adminOrLocation: false,
      buttonLoad: false,
      amplifyUI: AmplifyUI,
      amplifyUI: {
        phone: {},
        formSection: "Form__formSection___3tqxz",
        sectionHeader: "Section__sectionHeader___13iO4",
        sectionBody: "Section__sectionBody___3DCrX",
        formField: "Form__formField___2DWhT",
        inputLabel: "Input__inputLabel___nQolz",
        input: "Input__input___2Sh1s",
        sectionFooter: "Section__sectionFooter___1QlDn",
        sectionFooterPrimaryContent:
          "Section__sectionFooterPrimaryContent___36Gb1",
        button: "Button__button___1FrBC",
        sectionFooterSecondaryContent:
          "Section__sectionFooterSecondaryContent___3cjOa",
        a: "Anchor__a___3JUCG",
      },
      error: "",
      logger: {},
      defaultSignUpFields,
      phoneNumber: "",
    };
  },
  computed: {
    options() {
      let header = this.$Amplify.I18n.get("Create a new account");

      if (
        this.signUpConfig &&
        this.signUpConfig.hiddenDefaults &&
        this.signUpConfig.hiddenDefaults.length > 0
      ) {
        this.defaultSignUpFields = this.defaultSignUpFields.filter((d) => {
          return !this.signUpConfig.hiddenDefaults.includes(d.key);
        });
      }

      if (this.signUpConfig && this.signUpConfig.hideAllDefaults) {
        this.defaultSignUpFields = [];
      }

      // begin looping through signUpFields
      if (
        this.signUpConfig &&
        this.signUpConfig.signUpFields &&
        this.signUpConfig.signUpFields.length > 0
      ) {
        // if hideAllDefaults and hideDefaults are not present on props...
        if (
          !this.signUpConfig.hideAllDefaults &&
          !this.signUpConfig.hideDefaults
        ) {
          // ...add default fields to signUpField array unless user has passed in custom field with matching key
          this.defaultSignUpFields.forEach((f, i) => {
            const matchKey = this.signUpConfig.signUpFields.findIndex((d) => {
              return d.key === f.key;
            });
            if (matchKey === -1) {
              this.signUpConfig.signUpFields.push(f);
            }
          });
        }
        /* 
          sort fields based on following rules:
          1. Fields with displayOrder are sorted before those without displayOrder
          2. Fields with conflicting displayOrder are sorted alphabetically by key
          3. Fields without displayOrder are sorted alphabetically by key
        */
        this.signUpConfig.signUpFields.sort((a, b) => {
          if (a.displayOrder && b.displayOrder) {
            if (a.displayOrder < b.displayOrder) {
              return -1;
            } else if (a.displayOrder > b.displayOrder) {
              return 1;
            } else {
              if (a.key < b.key) {
                return -1;
              } else {
                return 1;
              }
            }
          } else if (!a.displayOrder && b.displayOrder) {
            return -1;
          } else if (a.displayOrder && !b.displayOrder) {
            return 1;
          } else if (!a.displayOrder && !b.displayOrder) {
            if (a.key < b.key) {
              return 1;
            } else {
              return -1;
            }
          }
        });
      }

      return Object.assign(
        { header, signUpFields: this.defaultSignUpFields },
        this.signUpConfig || {}
      );
    },
    orderedSignUpFields: function () {
      return orderBy(this.options.signUpFields, "displayOrder", "name");
    },
  },
  mounted() {
    this.logger = new this.$Amplify.Logger(this.$options.name);
  },
  methods: {
    checkUserType: async function (code) {
      let getUser = this.$store.dispatch("user/getUserCode", code)
      let getLoc = this.$store.dispatch("user/getLocationCode", code)
      let [user, loc] = await Promise.all([getUser, getLoc])
      if (!user && !loc) return false
      else {
        return {
          id: user ? user : loc,
          auth: user ? "admin" : "location"
        }
      }
    },
    signUp: async function () {
      if (!this.validate()) {
        return null;
      }

      // set loading
      // this.$store.commit("user/setLoading", true)
      this.buttonLoad = true

      let user = {
        attributes: {},
      };

      // puts field data into 'Auth.signUp' parameter structure
      this.options.signUpFields.forEach((e) => {
        if (e.key === "username") {
          user.username = e.value;
        } else if (e.key === "password") {
          user.password = e.value;
        } else if (e.key === "phone_number" && e.value) {
          user.attributes.phone_number = e.value;
        } else {
          const newKey = `${this.needPrefix(e.key) ? "custom:" : ""}${e.key}`;
          user.attributes[newKey] = e.value;
        }
      });

      let labelCheck = false;
      this.options.signUpFields.forEach((field) => {
        if (field.label === this.getUsernameLabel()) {
          this.logger.debug(
            `Changing the username to the value of ${field.label}`
          );
          user.username = user.attributes[field.key] || user.username;
          labelCheck = true;
        }
      });
      if (!labelCheck && !user.username) {
        // if the customer customized the username field in the sign up form
        // He needs to either set the key of that field to 'username'
        // Or make the label of the field the same as the 'usernameAttributes'
        throw new Error(
          `Couldn't find the label: ${this.getUsernameLabel()}, in sign up fields according to usernameAttributes!`
        );
      }

      // check for user types
      if (user.attributes["custom:distributor"] === "bwrsuper") {
        user.attributes["custom:auth"] = "super";
      } else if (user.attributes["custom:distributor"] === "customer") {
        user.attributes["custom:auth"] = "customer";
        // user.attributes["custom:code"] = "000000";
      }
      else if (user.attributes["custom:distributor"] === "fuelcell") {
        user.attributes["custom:auth"] = "fuelcell";
        // user.attributes["custom:code"] = "000000";
      }
      else if (!isNaN(user.attributes["custom:distributor"]) && user.attributes["custom:distributor"]) {
        let res = await this.checkUserType(user.attributes["custom:distributor"])
        if (res) {
          if (res.auth === "admin") user.attributes["custom:admin"] = res.id
          else user.attributes["custom:location"] = res.id
          user.attributes["custom:auth"] = res.auth
          delete user.attributes["custom:distributor"]
          this.adminOrLocation = true
        }
        else {
          this.setError("Code is incorrect")
          return
        }
      }
       else if (
        user.attributes["custom:distributor"] === "E1test" ||
        user.attributes["custom:distributor"] === "mdxdemo"
      ) {
        user.attributes["custom:auth"] = "mdx";
      } else if (user.attributes["custom:distributor"] === "kdxdemo") {
        user.attributes["custom:auth"] = "kdx";
      } else if (user.attributes["custom:distributor"] === "tdxdemo") {
        user.attributes["custom:auth"] = "tdx";
      } else if (user.attributes["custom:distributor"] === "bwrdistributor") {
        user.attributes["custom:auth"] = "distributor";
        user.attributes["custom:distributor"] = "bwr";
      } else if (user.attributes["custom:distributor"] === "elementone") {
        user.attributes["custom:auth"] = "distributor";
        user.attributes["custom:distributor"] = "elementone";
      } else if (
        user.attributes["custom:distributor"] === "kappusdistributor"
      ) {
        user.attributes["custom:auth"] = "distributor";
        user.attributes["custom:distributor"] = "kappus";
      } else if (user.attributes["custom:distributor"] === "papademo") {
        user.attributes["custom:auth"] = "user";
        user.attributes["custom:distributor"] = "tdxdemo";
        user.attributes["custom:store"] = "";
      } else if (user.attributes["custom:store"] === "kappusstore") {
        user.attributes["custom:auth"] = "store";
        user.attributes["custom:distributor"] = "kappus";
        user.attributes["custom:store"] = "kappus";
      } else if (user.attributes["custom:store"] === "kappusstore2") {
        user.attributes["custom:auth"] = "store";
        user.attributes["custom:distributor"] = "kappus";
        user.attributes["custom:store"] = "kappus2";
      } else if (user.attributes["custom:store"] === "bwrstore") {
        user.attributes["custom:auth"] = "store";
        user.attributes["custom:distributor"] = "bwr";
        user.attributes["custom:store"] = "bwr";
      } else if (user.attributes["custom:store"] === "bwruser") {
        user.attributes["custom:auth"] = "user";
        user.attributes["custom:distributor"] = "bwr";
        user.attributes["custom:store"] = "";
      } else {
        // user.attributes["custom:distributor"] = "customer";
        // user.attributes["custom:auth"] = "customer";
        // user.attributes["custom:auth"] = "user";
        // user.attributes["custom:distributor"] = "kappus";
        // user.attributes["custom:store"] = "";
      }

      if (!user.attributes["custom:distributor"] && !this.adminOrLocation) {
        user.attributes["custom:distributor"] = "customer";
        user.attributes["custom:auth"] = "customer";
      }
      if (!user.attributes["custom:store"] && !this.adminOrLocation) {
        user.attributes["custom:store"] = "";
      }

      user.username = user.username.toLowerCase();
      let first = user.attributes.given_name.toLowerCase();
      let last = user.attributes.family_name.toLowerCase();
      user.attributes.given_name = `${first
        .charAt(0)
        .toUpperCase()}${first.slice(1)}`;
      user.attributes.family_name = `${last
        .charAt(0)
        .toUpperCase()}${last.slice(1)}`;
      user.attributes.email = user.attributes.email.toLowerCase();

      if (!user.attributes.phone_number) {
        delete user.attributes.phone_number;
      }

      // if (this.$route.name === "addSingleDevice") {
      //   this.$store.commit("user/setLoading", true)
      // }

      this.$Amplify.Auth.signUp(user)
        .then(async (data) => {
          AmplifyEventBus.$emit("localUser", data.user);
          if (!data.userConfirmed) {
            await this.$store.dispatch("user/pendingUser");
            this.$router.push("/pending-verification");
          } else {
              this.$Amplify.Auth.signIn(user.username, user.password)
              .then(async (user) => {
                await this.$store.dispatch("user/login", user);
                return AmplifyEventBus.$emit("authState", "signedIn");
              })
              .catch((err) => {
                return AmplifyEventBus.$emit("authState", "signIn");
              });
          }
        })
        .catch((e) => this.setError(e));
    },
    validate: function () {
      this.error = "";
      let invalids = [];
      this.options.signUpFields.map((el) => {
        if (el.required && !el.value) {
          invalids.push(el.label);
          Vue.set(el, "invalid", true);
        }
        return el;
      });
      if (invalids.length > 0) {
        this.setError(
          `The following fields must be completed: ${invalids.join(", ")}`
        );
      }
      return invalids.length < 1;
    },
    signIn: function () {
      AmplifyEventBus.$emit("authState", "signIn");
    },
    clear(field) {
      if (field && field.invalid && field.value) {
        Vue.set(field, "invalid", false);
      }
    },
    setError: function (e) {
      // this.$store.commit("user/setLoading", false)
      this.buttonLoad = false
      this.error = this.$Amplify.I18n.get(e.message || e);
      this.logger.error(this.error);
    },

    // determines whether or not key needs to be prepended with 'custom:' for Cognito User Pool custom attributes.
    needPrefix: function (key) {
      const field = this.options.signUpFields.find((e) => e.key === key);
      if (key.indexOf("custom:") !== 0) {
        return field.custom;
      } else if (key.indexOf("custom:") === 0 && field.custom === false) {
        this.logger.warn(
          "Custom prefix prepended to key but custom field flag is set to false"
        );
      }
      return null;
    },

    getUsernameLabel: function () {
      return labelMap[this.usernameAttributes] || this.usernameAttributes;
    },

    phoneNumberChanged: function (data) {
      const phoneNumberField = this.options.signUpFields.filter(
        (field) => field.key === "phone_number"
      )[0];
      this.clear(phoneNumberField);
      phoneNumberField.value = data.local_phone_number.replace(/[() -]+/g, "");
    },
  },
};
</script>