<template>
  <div v-bind:class="amplifyUI.formSection" v-bind:data-test="auth.signIn.section">
    <div
      v-bind:class="amplifyUI.sectionHeader"
      v-bind:data-test="auth.signIn.headerSection"
    >{{options.header}}</div>
    <div v-bind:class="amplifyUI.sectionBody" v-bind:data-test="auth.signIn.bodySection">
      <username-field
        v-bind:usernameAttributes="usernameAttributes"
        v-on:username-field-changed="usernameFieldChanged"
      ></username-field>
      <div class="Form__formField___2DWhT">
        <div v-bind:class="amplifyUI.inputLabel">{{$Amplify.I18n.get('Password')}} *</div>
        <input
          v-bind:class="amplifyUI.input"
          v-model="password"
          type="password"
          :placeholder="$Amplify.I18n.get('Enter your password')"
          v-on:keyup.enter="signIn"
          v-bind:data-test="auth.signIn.passwordInput"
        />
        <div v-bind:class="amplifyUI.hint">
          {{$Amplify.I18n.get('Forget your password? ')}}
          <a
            v-bind:class="amplifyUI.a"
            v-on:click="forgot"
            v-bind:data-test="auth.signIn.forgotPasswordLink"
          >{{$Amplify.I18n.get('Reset password')}}</a>
        </div>
      </div>
    </div>
    <div v-bind:class="amplifyUI.sectionFooter" v-bind:data-test="auth.signIn.footerSection">
      <span v-bind:class="amplifyUI.sectionFooterPrimaryContent">
        <v-btn
          v-bind:class="amplifyUI.button"
          v-on:click="signIn"
          v-bind:data-test="auth.signIn.signInButton"
          :loading="buttonLoad"
          dark
          color="#002b44"
        >{{$Amplify.I18n.get('Sign In')}}</v-btn>
      </span>
      <span v-bind:class="amplifyUI.sectionFooterSecondaryContent" v-if="options.isSignUpDisplayed">
        {{$Amplify.I18n.get('No account? ')}}
        <a
          v-bind:class="amplifyUI.a"
          v-on:click="signUp"
          v-bind:data-test="auth.signIn.createAccountLink"
        >{{$Amplify.I18n.get('Create account')}}</a>
      </span>
    </div>
    <div class="error" v-if="error" v-bind:data-test="auth.signIn.signInError">{{ error }}</div>
  </div>
</template>

<script>
import { AmplifyEventBus } from "aws-amplify-vue";
import * as AmplifyUI from "@aws-amplify/ui";
import Vue from "vue";
import UsernameField from "@/components/UsernameField";
Vue.component("amplify-username-field", UsernameField);
import { auth } from "aws-amplify-vue/src/assets/data-test-attributes";

export default {
  name: "SignIn",
  props: ["signInConfig", "usernameAttributes"],
  components: {
    UsernameField
  },
  data() {
    return {
      buttonLoad: false,
      password: "",
      error: "",
      amplifyUI: AmplifyUI,
      amplifyUI: {
        formSection: "Form__formSection___3tqxz",
        sectionHeader: "Section__sectionHeader___13iO4",
        sectionBody: "Section__sectionBody___3DCrX",
        formField: "Form__formField___2DWhT",
        inputLabel: "Input__inputLabel___nQolz",
        input: "Input__input___2Sh1s",
        hint: "Hint__hint___3Hrww",
        sectionFooter: "Section__sectionFooter___1QlDn",
        sectionFooterPrimaryContent:
          "Section__sectionFooterPrimaryContent___36Gb1",
        button: "Button__button___1FrBC",
        sectionFooterSecondaryContent:
          "Section__sectionFooterSecondaryContent___3cjOa",
        a: "Anchor__a___3JUCG"
      },
      auth,
      logger: {},
      signInUsername: "",
      labelMap: {
        email: "Email",
        phone_number: "Phone Number",
        username: "Username"
      }
    };
  },
  computed: {
    options() {
      const defaults = {
        header: this.$Amplify.I18n.get("Sign in to your account"),
        username: "",
        isSignUpDisplayed: true
      };
      return Object.assign(defaults, this.signInConfig || {});
    }
  },
  mounted() {
    this.logger = new this.$Amplify.Logger(this.$options.name);
  },
  methods: {
    signIn: function(event) {
      // this.$store.commit("user/setLoading", true)
      this.buttonLoad = true
      this.$Amplify.Auth.signIn(
        this.signInUsername.toLowerCase(),
        this.password
      )
        .then(data => {
          this.logger.info("sign in success");
          if (
            data.challengeName === "SMS_MFA" ||
            data.challengeName === "SOFTWARE_TOKEN_MFA"
          ) {
            AmplifyEventBus.$emit("localUser", data);
            return AmplifyEventBus.$emit("authState", "confirmSignIn");
          } else if (data.challengeName === "NEW_PASSWORD_REQUIRED") {
            AmplifyEventBus.$emit("localUser", data);
            return AmplifyEventBus.$emit("authState", "requireNewPassword");
          } else if (data.challengeName === "MFA_SETUP") {
            AmplifyEventBus.$emit("localUser", data);
            return AmplifyEventBus.$emit("authState", "setMfa");
          } else if (
            data.challengeName === "CUSTOM_CHALLENGE" &&
            data.challengeParam &&
            data.challengeParam.trigger === "true"
          ) {
            AmplifyEventBus.$emit("localUser", data);
            return AmplifyEventBus.$emit("authState", "customConfirmSignIn");
          } else {
            return AmplifyEventBus.$emit("authState", "signedIn");
          }
        })
        .catch(async e => {
          if (e.code && e.code === "UserNotConfirmedException") {
            AmplifyEventBus.$emit("localUser", {
              username: this.signInUsername
            });
            await this.$store.dispatch("user/pendingUser");
            this.$router.push("/pending-verification");
            // AmplifyEventBus.$emit("authState", "confirmSignUp");
          } else {
            this.setError(e);
          }
        });
    },
    forgot: function() {
      AmplifyEventBus.$emit("authState", "forgotPassword");
    },
    signUp: function() {
      AmplifyEventBus.$emit("authState", "signUp");
    },
    setError: function(e) {
      // this.$store.commit("user/setLoading", false)
      this.buttonLoad = false
      this.error = this.$Amplify.I18n.get(e.message || e);
      this.logger.error(this.error);
    },
    usernameFieldChanged: function(data) {
      const { usernameField, username, email, phoneNumber } = data;
      switch (usernameField) {
        case "username":
          this.signInUsername = username;
          break;
        case "email":
          this.signInUsername = email;
          break;
        case "phone_number":
          this.signInUsername = phoneNumber;
          break;
        default:
          break;
      }
    }
  }
};
</script>
